/* eslint-disable no-unused-vars */ /* __next_internal_client_entry_do_not_use__ useProblemEditableStore,ProblemEditableStoreProvider auto */ import createZustandContext from "@features/shared/providers/createZustandContext";
import { createStore } from "zustand";
const ProblemEditableStore = createZustandContext((initialState)=>{
    return createStore()((set, get)=>({
            isProblemEditable: initialState.isProblemEditable,
            problemsImportCandidateId: initialState.problemsImportCandidateId,
            onEditChange: initialState.onEditChange,
            setIsProblemEditable: (value)=>set((state)=>{
                    return {
                        isProblemEditable: value
                    };
                })
        }));
});
export const useProblemEditableStore = ProblemEditableStore.useStore;
export const ProblemEditableStoreProvider = ProblemEditableStore.Provider;
