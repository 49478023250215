/* __next_internal_client_entry_do_not_use__ useQuestionGroupStore,QuestionGroupStoreProvider auto */ import createZustandContext from "@features/shared/providers/createZustandContext";
import { differenceWith, groupBy, isDeepEqual, keys } from "remeda";
import { createStore } from "zustand";
import { QuestionsViewMode } from "./QuestionsViewMode.types";
const QuestionGroupStore = createZustandContext((initialState)=>{
    return createStore()((set, get)=>({
            mode: initialState.mode,
            selectedAnswerChoices: initialState.selectedAnswerChoices,
            enteredAsnwerTexts: initialState.enteredAsnwerTexts,
            areAllAnswersBeingShown: initialState.areAllAnswersBeingShown,
            areAnswersBeingChecked: initialState.areAnswersBeingChecked,
            areCorrectAnswersBeingShown: initialState.areCorrectAnswersBeingShown,
            isCheckingAnswersInlineEnabled: initialState.isCheckingAnswersInlineEnabled,
            isChangingAnswersDisabled: initialState.isChangingAnswersDisabled,
            onAnswerChange: initialState.onAnswerChange,
            addEnteredAnswerText: (value)=>set((state)=>{
                    if (state.isChangingAnswersDisabled) return state;
                    const filteredEnteredAnswerTexts = state.enteredAsnwerTexts.filter((text)=>text.answerId !== value.answerId);
                    const newEnteredAnswerTexts = [
                        ...filteredEnteredAnswerTexts,
                        value
                    ];
                    if (state.onAnswerChange) {
                        state.onAnswerChange({
                            enteredAnswerTexts: newEnteredAnswerTexts.map((answer)=>({
                                    answer_text: answer.answerText,
                                    answer_id: answer.answerId,
                                    question_id: answer.questionId
                                })),
                            selectedAnswerChoices: [],
                            questionId: value.questionId
                        });
                    }
                    return {
                        enteredAsnwerTexts: newEnteredAnswerTexts
                    };
                }),
            setSelectedAnswerChoices: (value)=>set((state)=>{
                    if (state.isChangingAnswersDisabled) return state;
                    const addedAnswerChoicesByQuestionId = groupBy(differenceWith(value, state.selectedAnswerChoices, isDeepEqual), (choice)=>choice.questionId);
                    keys(addedAnswerChoicesByQuestionId).forEach((questionId)=>{
                        if (state.onAnswerChange) {
                            var _addedAnswerChoicesByQuestionId_questionId;
                            state.onAnswerChange({
                                enteredAnswerTexts: [],
                                selectedAnswerChoices: ((_addedAnswerChoicesByQuestionId_questionId = addedAnswerChoicesByQuestionId[questionId]) === null || _addedAnswerChoicesByQuestionId_questionId === void 0 ? void 0 : _addedAnswerChoicesByQuestionId_questionId.map((choice)=>({
                                        answer_id: choice.answerId,
                                        question_id: choice.questionId
                                    }))) || [],
                                questionId: parseInt(questionId, 10)
                            });
                        }
                    });
                    return {
                        selectedAnswerChoices: value
                    };
                }),
            setAreAnswersBeingChecked: (value)=>set((state)=>{
                    if (state.isChangingAnswersDisabled) return state;
                    return {
                        areAnswersBeingChecked: value
                    };
                }),
            setAreAllAnswersBeingShown: (value)=>set((state)=>{
                    if (state.isChangingAnswersDisabled) return state;
                    return {
                        areAllAnswersBeingShown: value
                    };
                }),
            isQuizResultsMode: ()=>get().mode === QuestionsViewMode.QUIZ_RESULTS,
            isExamMode: ()=>get().mode === QuestionsViewMode.EXAM,
            isPracticeMode: ()=>get().mode === QuestionsViewMode.PRACTICE
        }));
});
// export default QuestionGroupStore;
export const useQuestionGroupStore = QuestionGroupStore.useStore;
export const QuestionGroupStoreProvider = QuestionGroupStore.Provider;
