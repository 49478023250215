/* __next_internal_client_entry_do_not_use__ useProblemsImportReviewStore,ProblemsImportReviewStoreProvider auto */ import { unique } from "remeda";
import createZustandContext from "@features/shared/providers/createZustandContext";
import { createStore } from "zustand";
const ProblemsImportReviewStore = createZustandContext((initialState)=>{
    return createStore()((set, get)=>({
            problemsImportCandidates: initialState.problemsImportCandidates,
            problemsThatAreShowingComparisonData: initialState.problemsThatAreShowingComparisonData,
            selectedProblemIds: initialState.selectedProblemIds || [],
            addSelectedProblemIds: (value)=>set((state)=>{
                    return {
                        selectedProblemIds: [
                            ...state.selectedProblemIds,
                            value
                        ]
                    };
                }),
            removeSelectedProblemIds: (value)=>set((state)=>{
                    return {
                        selectedProblemIds: state.selectedProblemIds.filter((id)=>id !== value)
                    };
                }),
            setSelectedProblemIds: (value)=>set((state)=>{
                    return {
                        selectedProblemIds: value
                    };
                }),
            addProblemToShowingComparisonData: (value)=>set((state)=>{
                    return {
                        problemsThatAreShowingComparisonData: [
                            ...state.problemsThatAreShowingComparisonData,
                            value
                        ]
                    };
                }),
            toggleAllProblemsToShowingComparisonData: ()=>set((state)=>{
                    return {
                        problemsThatAreShowingComparisonData: state.problemsImportCandidates.map((candidate)=>candidate.id)
                    };
                }),
            removeProblemFromShowingComparisonData: (value)=>set((state)=>{
                    return {
                        problemsThatAreShowingComparisonData: state.problemsThatAreShowingComparisonData.filter((id)=>id !== value)
                    };
                }),
            bulkSelectProblemIds: (selectedProblemId)=>set((state)=>{
                    console.log("bulkSelectProblemIds", selectedProblemId);
                    const selectedProblemIndex = state.problemsImportCandidates.findIndex((candidate)=>candidate.id === selectedProblemId);
                    const lastSelectedProblemId = state.selectedProblemIds[state.selectedProblemIds.length - 1];
                    const lastSelectedProblemIndex = state.problemsImportCandidates.findIndex((candidate)=>candidate.id === lastSelectedProblemId);
                    const isCurrentProblemBeforeLastSelectedProblem = lastSelectedProblemIndex > selectedProblemIndex;
                    let newlySelectedIds = [];
                    if (isCurrentProblemBeforeLastSelectedProblem) {
                        newlySelectedIds = state.problemsImportCandidates.slice(selectedProblemIndex, lastSelectedProblemIndex + 1).map((candidate)=>candidate.id);
                    } else {
                        newlySelectedIds = state.problemsImportCandidates.slice(lastSelectedProblemIndex, selectedProblemIndex + 1).map((candidate)=>candidate.id);
                    }
                    const areAllNewlySelectedProblemsSelected = newlySelectedIds.every((id)=>state.selectedProblemIds.includes(id));
                    console.log({
                        selectedProblemIndex,
                        isCurrentProblemBeforeLastSelectedProblem,
                        newlySelectedIds,
                        areAllNewlySelectedProblemsSelected
                    });
                    if (areAllNewlySelectedProblemsSelected) {
                        return {
                            selectedProblemIds: state.selectedProblemIds.filter((id)=>!newlySelectedIds.includes(id))
                        };
                    } else {
                        return {
                            selectedProblemIds: unique([
                                ...state.selectedProblemIds,
                                ...newlySelectedIds
                            ])
                        };
                    }
                })
        }));
});
export const useProblemsImportReviewStore = ProblemsImportReviewStore.useStore;
export const ProblemsImportReviewStoreProvider = ProblemsImportReviewStore.Provider;
