import { useMutation, useQueryClient } from '@tanstack/react-query';
import { splitAt } from 'remeda';
import { v4 as uuidv4 } from 'uuid';
import { apiClient } from '@features/api/api.client';
import { ProblemsImportCandidateStatusEnum } from '@features/api/client';
import useApiClient from '@features/api/useApiClient';

function useSplitProblemCandidate(problemSourceId: number) {
	const queryClient = useQueryClient();
	const { mutateAsync: updateProblemsImportAttempt } =
		apiClient.problemsImport.useUpdateProblemsImportCandidate({ problemSourceId });
	const { mutateAsync: createProblemsImportCandidate } =
		apiClient.problemsImport.useCreateProblemsImportCandidate({ problemSourceId });

	const { data: problemsImportCandidates } = apiClient.problemsImport.useGetProblemsImportCandidateList({
		requestData: {
			problem_source: problemSourceId,
			status: ProblemsImportCandidateStatusEnum.Unprocessed,
		},
	});

	const { queries } = useApiClient();
	const queryKey = queries.problemImports.candidates._ctx.list({
		problem_source: problemSourceId,
	}).queryKey;

	return useMutation({
		mutationFn: async ({
			questionIndex,
			problemsImportCandidateId,
		}: {
			questionIndex: number;
			problemsImportCandidateId: number;
		}) => {
			const selectedProblemCandidate = problemsImportCandidates?.results.find(
				(problemsImportCandidate) => problemsImportCandidate.id === problemsImportCandidateId
			);

			if (!selectedProblemCandidate) throw Error('Problem candidate not found');

			const selectedProblem = selectedProblemCandidate.problem_data;

			const questions = splitAt(selectedProblem.questions, questionIndex);

			const oldProblem = { ...selectedProblem, questions: questions[0] };
			const newProblem = {
				...selectedProblem,
				import_uuid: uuidv4(),
				problem_number: questions[1][0]?.question_number || selectedProblem.problem_number,
				questions: questions[1],
			};

			const updatePromise = updateProblemsImportAttempt({
				id: selectedProblemCandidate.id,
				patchedProblemsImportCandidateRequest: {
					problem_data: oldProblem,
				},
			});
			const createPromise = createProblemsImportCandidate({
				title:
					selectedProblemCandidate.title?.split('-').slice(0, 2).join('-') +
					`- ${newProblem.problem_number}`,
				problem_data: newProblem,
				problem_source: problemSourceId,
				problem_source_section: selectedProblemCandidate.problem_source_section,
				problems_import_attempt: selectedProblemCandidate.problems_import_attempt,
			});

			const promises = [updatePromise, createPromise];

			return Promise.all(promises);
		},
		onMutate: async (requestData) => {
			await queryClient.cancelQueries({
				queryKey,
			});

			const previousData = await queryClient.getQueryData(queryKey);

			// queryClient.setQueryData(
			// 	queryKey,
			// 	(old: PaginatedProblemsImportAttemptListList) =>
			// 		({
			// 			...old,
			// 			results: old.results.map((problemsImport) => ({
			// 				...problemsImport,
			// 				images: problemsImport.ordered_images
			// 					.map((image) => {
			// 						if (image.id === requestData.id) {
			// 							return {
			// 								...image,
			// 								...requestData.patchedProblemsImportAttemptCandidateRequest,
			// 							};
			// 						}

			// 						return image;
			// 					})
			// 					.toSorted((a, b) => a.id - b.id),
			// 			})),
			// 		}) satisfies PaginatedProblemsImportAttemptListList
			// );

			return { previousData };
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey });
		},
		onError: (err, requestData, context) => {
			queryClient.setQueryData(queryKey, context?.previousData);
		},
	});
}

export default useSplitProblemCandidate;
