import type { QuestionTypeEnum } from '@features/api/client';
import type { schemas } from '@features/api/schema';
import { uniqueBy } from 'remeda';

type NormalizeQuestionsIntoGroupsProps = {
	questions: Array<schemas['Question']>;
};

export type QuestionGroup = {
	type: QuestionTypeEnum;
	questions: Array<schemas['Question']>;
};

export function normalizeQuestionsIntoGroups({ questions }: NormalizeQuestionsIntoGroupsProps) {
	const questionGroups = questions.reduce<Array<QuestionGroup>>(
		(groups, currentQuestion, currentIndex, initialQuestions) => {
			const prevQuestion = initialQuestions[currentIndex - 1];

			if (prevQuestion && prevQuestion.question_type === currentQuestion.question_type) {
				const prevGroup = groups[groups.length - 1];
				if (!prevGroup) return groups;

				prevGroup.questions.push(currentQuestion);
				return groups;
			}

			groups.push({ type: currentQuestion.question_type, questions: [currentQuestion] });
			return groups;
		},
		[]
	);

	return questionGroups;
}

export function concatQuestionTextsInsideGroup(
	questions: Array<schemas['Question']>,
	leadText?: string | null | undefined
) {
	const questionText = questions.reduce<string>((text, currentQuestion, currentIndex) => {
		let newText = leadText;
		const currentText = currentQuestion.text;
		if (!currentText) return text;

		newText =
			text +
			currentText +
			`<span class="select-placeholder select-placeholder-${currentQuestion.problem}" data-index="${currentIndex}"></span>`;

		const postAnswerText = currentQuestion.postanswer_text;
		if (!postAnswerText) return newText + ' ';

		newText = newText + postAnswerText + ' ';

		if (currentQuestion.question_display_type === 'vertical') {
			newText = newText + '<br/><br/>';
		}

		return newText;
	}, '');

	return questionText;
}

export function normalizeSamePoolAnswers(questions: Array<schemas['Question']>) {
	if (typeof document === 'undefined') return { uniqueAnswers: [], initialAnswers: [] };

	const initialAnswers = questions
		.map((question) => question.answers.filter((answer) => answer.answer_type === 'answer_choice'))
		.flat()
		.map((answer) => {
			const element = document.createElement('div');
			element.innerHTML = answer.display_text;

			return { ...answer, innnerText: element.innerText.trim() };
		});

	const uniqueAnswers = uniqueBy(initialAnswers, (answer) => answer.innnerText).map((ans) => {
		const correctAnswer = initialAnswers.find((a) => a.innnerText === ans.innnerText && a.is_correct);

		if (!correctAnswer) return ans;

		return correctAnswer;
	});

	return { uniqueAnswers, initialAnswers };
}
